import {
  Body,
  BodyHeavy,
  Caption,
  CaptionHeavy,
  Colors,
  DarkThemeQuery,
  Icon,
  LightThemeQuery,
  MediaQuery,
  SmallLight,
  SmartLink,
  Theme,
} from '@pelando/components';
import styled, { css } from 'styled-components';
import { BOTTOM_NAVIGATION_HEIGHT } from '../../components/BottomNavigation';
import { maximumGridStyle } from '../../styles/global';

type FooterProps = {
  $hasAd?: boolean;
  $removeMargin?: boolean;
};

export const FooterContainer = styled.footer<FooterProps>`
  background-color: rgb(${({ theme }) => theme.colors.Juliet});
  padding-bottom: ${({ $hasAd }) => ($hasAd ? '120px' : '0')};
  margin-top: ${({ $removeMargin }) => ($removeMargin ? '0' : '24px')};
`;

export const FooterContent = styled.div`
  padding: 40px 32px 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;

  @media ${MediaQuery.SCREEN_MD_UP} {
    flex-direction: row;
    padding: 60px 0;
    ${maximumGridStyle}
  }
`;

export const FooterLogoText = styled.div`
  color: rgb(${({ theme }) => theme.colors.Alpha});
  max-width: 260px;

  a {
    width: 152px;
  }

  @media ${MediaQuery.SCREEN_MD_UP} {
    a {
      width: 188px;
    }
  }
`;

export const FooterText = styled.p`
  color: rgb(${({ theme }) => theme.colors.Alpha});
  ${BodyHeavy}
  margin: 22px 0 8px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    margin-top: 40px 0 16px;
  }
`;
export const FooterSubText = styled.span`
  ${Body}
  color: rgb(${Colors.Gray});
  @media ${MediaQuery.SCREEN_MD_UP} {
  }
`;

export const FooterInfo = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  gap: 56px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    display: contents;
    justify-content: space-between;
  }
`;

export const FooterList = styled.ul``;

export const FooterListItem = styled.li`
  list-style-type: none;
  margin-bottom: 12px;
`;

export const FooterListItemLink = styled(SmartLink)`
  ${Caption}
  color: rgb(${({ theme }) => theme.colors.Alpha});
  text-decoration: none;
`;

const footerTitleStyle = css`
  ${BodyHeavy}
  margin-bottom: 20px;
  align-items: center;
  display: block;
  @media ${MediaQuery.SCREEN_MD_UP} {
    margin-bottom: 16px;
  }
`;

export const FooterTitle = styled.span`
  ${footerTitleStyle}
`;

export const FooterTitleInList = styled.li`
  list-style: none;
  ${footerTitleStyle}
`;

const footerAccessStyle = css`
  ${BodyHeavy}
  margin-bottom: 32px;
  @media ${MediaQuery.SCREEN_MD_UP} {
    margin-bottom: 36px;
  }
`;

export const FooterAccess = styled.div`
  ${footerAccessStyle}
  gap: 16px;
`;

export const FooterAccessMobile = styled.div`
  ${footerAccessStyle}
  display: block;
  @media ${MediaQuery.SCREEN_MD_UP} {
    display: none;
  }
`;

export const FooterAccessApp = styled.div`
  ${footerAccessStyle}
  display: none;
  @media ${MediaQuery.SCREEN_MD_UP} {
    display: block;
  }
`;

export const FooterSubtitleApp = styled.div`
  ${SmallLight}
  margin-bottom: 8px;
  margin-top: -12px;
`;

export const QRCodeImage = styled.div`
  background-repeat: no-repeat;
  width: 93px;
  height: 93px;
  display: flex;
  align-items: center;
  margin-top: 12px;
  border: none;

  ${LightThemeQuery} {
    background-image: url('/assets/qrcodeapp-light.svg');
  }

  ${DarkThemeQuery} {
    background-image: url('/assets/qrcodeapp-dark.svg');
  }
`;

export const FooterApp = styled.div`
  ${BodyHeavy}
  display: flex;
  @media ${MediaQuery.SCREEN_MD_UP} {
    display: none;
  }
`;

export const FooterStoreUrl = styled(SmartLink)`
  text-decoration: none;
`;

export const FooterStoreUrlButton = styled.div`
  display: flex;
  cursor: pointer;
  ${CaptionHeavy}
  padding: 8px 12px;
  height: 32px;
  align-items: center;
  border-radius: 20px;
  margin-bottom: unset;
  margin-right: 12px;
  min-width: 132px;
  color: rgb(${Colors.White});
  background-color: rgb(${Colors.Graphite});
  @media ${MediaQuery.SCREEN_MD_UP} {
    margin-bottom: 12px;
  }
`;

export const FooterFollowUrl = styled(SmartLink)`
  color: rgb(${({ theme }) => theme.colors.Alpha});
  text-decoration: none;
  display: inline-flex;
`;

export const FooterGapFollowUrl = styled.span`
  display: flex;
  gap: 16px;
`;

export const FooterFollowIcons = styled(Icon)`
  cursor: pointer;
`;

export const FooterDivisorLine = styled.hr`
  width: 100%;
  height: 1px;
  background-color: rgb(${Colors.Silver});
  margin: 40px 0;

  @media ${MediaQuery.SCREEN_MD_UP} {
    display: none;
  }
`;

export const FooterAdress = styled.address`
  padding: 32px;
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  white-space: pre;
  justify-content: center;
  font-style: unset;
  margin-bottom: ${BOTTOM_NAVIGATION_HEIGHT}px;
  background-color: rgb(${Theme.colors.Kilo});
  ${Caption}

  @media ${MediaQuery.SCREEN_MD_UP} {
    padding: 32px 0;
    margin-bottom: unset;
  }
`;
