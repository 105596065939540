import { Icon, HeaderLogo as FooterLogo } from '@pelando/components';
import React from 'react';
import {
  GooglePlay,
  Apple,
  Instagram,
  Facebook,
  Twitter,
  Tiktok,
} from '@pelando/fontawesome/icons';
import { useRouter } from 'next/router';
import Translation from '../../components/Translation';
import { useTranslation } from '../../hooks/useTranslation';
import {
  getAllStoresUrl,
  getContactPelandoUrl,
  getFacebookUrl,
  getInstagramUrl,
  getTikTokUrl,
  getTwitterUrl,
} from '../../services/urls';
import {
  FooterAdress,
  FooterContainer,
  FooterContent,
  FooterList,
  FooterListItem,
  FooterLogoText,
  FooterSubText,
  FooterText,
  FooterTitle,
  FooterAccess,
  FooterStoreUrl,
  FooterFollowUrl,
  FooterInfo,
  FooterApp,
  FooterDivisorLine,
  FooterListItemLink,
  FooterStoreUrlButton,
  FooterFollowIcons,
  FooterTitleInList,
  FooterAccessApp,
  QRCodeImage,
  FooterAccessMobile,
  FooterSubtitleApp,
  FooterGapFollowUrl,
} from './style';

export type RouterLinkItem = {
  title: string;
  url: string;
};
export type FooterProps = {
  removeMargin?: boolean;
};

export function Footer({ removeMargin }: FooterProps) {
  const { t } = useTranslation('footer');
  const { locale } = useRouter();
  const institutionalRouterUrl = [
    {
      title: t('title-privacy-policy'),
      url: t('link-privacy-policy'),
    },
    {
      title: t('title-terms-of-service'),
      url: t('link-terms-of-service'),
    },
    {
      title: t('title-code-of-conduct'),
      url: t('link-code-of-conduct'),
    },
  ];

  const supportRouterUrl = [
    { title: t('support-stores'), url: getAllStoresUrl() },
    { title: t('support-contact-us'), url: getContactPelandoUrl() },
  ];

  const storeUrl = {
    googlePlay:
      'https://play.google.com/store/apps/details?id=com.tippingcanoe.pelando',
    appleStore:
      'https://apps.apple.com/br/app/pelando-promo%C3%A7%C3%B5es-e-cupons/id1045614200',
  };

  return (
    <FooterContainer $hasAd={false} $removeMargin={removeMargin}>
      <FooterContent>
        <FooterLogoText>
          <FooterLogo url="/" locale={locale || ''} />
          <FooterText>{t('footer-text')}</FooterText>
          <FooterSubText>{t('footer-subtext')}</FooterSubText>
        </FooterLogoText>
        <FooterDivisorLine />
        <FooterInfo>
          <FooterList>
            <FooterTitleInList>{t('institutional')}</FooterTitleInList>
            {institutionalRouterUrl.map((item) => (
              <FooterListItem key={item.url}>
                <FooterListItemLink url={item.url}>
                  {item.title}
                </FooterListItemLink>
              </FooterListItem>
            ))}
            {locale === 'pt-BR' ? (
              <FooterListItem>
                <FooterListItemLink
                  url="https://ajuda.pelando.com.br/hc/pt-br"
                  rel="nofollow"
                >
                  {t('support-help-center')}
                </FooterListItemLink>
              </FooterListItem>
            ) : null}
          </FooterList>
          <FooterList>
            <FooterTitleInList>{t('support')}</FooterTitleInList>
            {supportRouterUrl.map((item) => (
              <FooterListItem key={item.url}>
                <FooterListItemLink url={item.url} target="_blank">
                  {item.title}
                </FooterListItemLink>
              </FooterListItem>
            ))}
            {locale !== 'pt-BR' ? (
              <FooterListItem>
                <FooterListItemLink
                  url="https://ajuda.pelando.com.br/hc/pt-br"
                  rel="nofollow"
                >
                  {t('support-help-center')}
                </FooterListItemLink>
              </FooterListItem>
            ) : null}
          </FooterList>
        </FooterInfo>
        <FooterDivisorLine />
        {locale === 'pt-BR' ? (
          <FooterAccessMobile>
            <FooterTitle>{t('download-the-app')}</FooterTitle>
            <FooterApp>
              <FooterStoreUrl url={storeUrl.googlePlay} target="_blank">
                <FooterStoreUrlButton>
                  <Icon icon={GooglePlay} role="img" style={{ fontSize: 24 }} />
                  Google play
                </FooterStoreUrlButton>
              </FooterStoreUrl>
              <FooterStoreUrl url={storeUrl.appleStore} target="_blank">
                <FooterStoreUrlButton>
                  <Icon icon={Apple} role="img" style={{ fontSize: 24 }} />
                  App store
                </FooterStoreUrlButton>
              </FooterStoreUrl>
            </FooterApp>
          </FooterAccessMobile>
        ) : null}
        <FooterAccess>
          <FooterTitle>{t('follow')}</FooterTitle>
          <FooterGapFollowUrl>
            <FooterFollowUrl
              url={getInstagramUrl()}
              target="_blank"
              title={t('instagram-title')}
            >
              <FooterFollowIcons
                icon={Instagram}
                role="img"
                style={{ fontSize: 24 }}
              />
            </FooterFollowUrl>
            <FooterFollowUrl
              url={getFacebookUrl()}
              target="_blank"
              title={t('facebook-title')}
            >
              <FooterFollowIcons
                icon={Facebook}
                role="img"
                style={{ fontSize: 24 }}
              />
            </FooterFollowUrl>
            <FooterFollowUrl
              url={getTwitterUrl()}
              target="_blank"
              title={t('twiter-title')}
            >
              <FooterFollowIcons
                icon={Twitter}
                role="img"
                style={{ fontSize: 24 }}
              />
            </FooterFollowUrl>
            <FooterFollowUrl
              url={getTikTokUrl()}
              target="_blank"
              title={t('tiktok-title')}
            >
              <FooterFollowIcons
                icon={Tiktok}
                role="img"
                style={{ fontSize: 24 }}
              />
            </FooterFollowUrl>
          </FooterGapFollowUrl>
        </FooterAccess>
        {locale === 'pt-BR' ? (
          <FooterAccessApp>
            <FooterTitle>
              <Translation translation={t('footer-app-title')}>
                Acesse descontos <br /> exclusivos no App
              </Translation>
            </FooterTitle>
            <FooterSubtitleApp>{t('footer-app-subtitle')}</FooterSubtitleApp>
            <QRCodeImage />
          </FooterAccessApp>
        ) : null}
      </FooterContent>
      <FooterAdress>
        <span>Av. Getúlio Vargas, 1492 - Funcionários, </span>{' '}
        <span>Belo Horizonte - MG - CEP 30112-021</span>
      </FooterAdress>
    </FooterContainer>
  );
}
